import React from "react"
import { graphql } from "gatsby"
import Layout from "@/components/layout"
import Sections from "@/components/sections"
import Seo from "@/components/seo"
import GetInTouch from "@/components/sections/get-in-touch"

const CaseStudyPage = ({ data, pageContext }) => {
  const { contentSections, metadata } = data.strapiCasestudy
  const global = data.strapiGlobal
  const navbar = data.strapiNavbar
  const footer = data.strapiFooter

  return (
    <>
      <Seo seo={metadata} global={global} />
      <Layout
        navbar={navbar}
        footer={footer}
        pageContext={{ ...pageContext }}
        slug="our-work"
      >
        <article className="insight">
          <Sections sections={contentSections} />
        </article>
        {/* <GetInTouch
          data={{
            __component: "sections.get-in-touch",
            id: 1,
            description:
              "Whether you’re ready to hire a Hooligan or just have a problem or project you want to discuss, drop us your details so we can get in touch. ",
            breadcrumbTitle: {
              id: 4,
              title: "Get in Touch",
              colour: "lightgrey",
              colourOverride: null,
            },
            title: [
              {
                id: 68,
                text: "<",
                style: "bold",
                colour: "green",
                colourOverride: null,
              },
              {
                id: 69,
                text: "Hello ",
                style: "italic",
                colour: "orange",
                colourOverride: null,
              },
              {
                id: 70,
                text: "Hooli",
                style: "bold",
                colour: "white",
                colourOverride: null,
              },
              {
                id: 71,
                text: "/>",
                style: "bold",
                colour: "green",
                colourOverride: null,
              },
            ],
          }}
        /> */}
      </Layout>
    </>
  )
}

export default CaseStudyPage

export const query = graphql`
  fragment GlobalDataCaseStudies on StrapiGlobal {
    favicon {
      localFile {
        publicURL
      }
    }
    id
    metaTitleSuffix
    metadata {
      metaDescription
      metaTitle
      metaKeywords
      shareImage {
        localFile {
          publicURL
        }
      }
    }
  }

  fragment NavbarData on StrapiNavbar {
    button {
      id
      newTab
      text
      type
      url
    }
    id
    links {
      url
      text
      newTab
      id
    }
  }

  fragment FooterData on StrapiFooter {
    id
    columns {
      id
      links {
        id
        newTab
        text
        url
      }
      title
    }
    id
    smallText
    email
    policies {
      id
      newTab
      text
      pdf {
        url
      }
    }
  }

  query DynamicCasestudyQuery($slug: String!) {
    strapiGlobal(locale: { eq: "en" }) {
      ...GlobalDataCaseStudies
    }
    strapiNavbar {
      ...NavbarData
    }
    strapiFooter {
      ...FooterData
    }
    strapiCasestudy(slug: { eq: $slug }) {
      id
      title
      blurb
      metadata {
        metaTitle
        metaDescription
        metaKeywords
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      contentSections
      cardMetadata {
        promoItem {
          backgroundGradient
          breadcrumbTitle {
            dashColour
            colour
            id
            title
          }
          background {
            formats {
              large {
                url
              }
            }
            ext
          }
          button {
            id
            newTab
            text
            type
            url
          }
          description
          id
          title {
            colour
            id
            style
            text
          }
        }
        id
      }
      categories {
        name
        slug
      }
    }
  }
`
